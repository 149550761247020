import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import "./Display.css";
import ResourcesDisplay from "./ResourcesDisplay";

const Resources = () => {
  const [resources, setResources] = useState({});

  useEffect(() => {
    axios.get("/getResources").then((response) => {
      const allData = response.data.resources;
      const sortedData = allData.sort((a, b) => {
        return a.category < b.category ? -1 : a.category > b.category ? 1 : 0;
      });
      setResources(sortedData);
    });
  }, []);

  let pageDisplay = "";

  if (Object.keys(resources).length) {
    pageDisplay = <ResourcesDisplay resources={resources} sort="filter" />;
  } else {
    pageDisplay = (
      <Loader type="Audio" color="rgb(190, 147, 250)" height={80} width={80} />
    );
  }

  return (
    <div className="resources">
      <div className="resourceHeadContainer">
        <h1 className="pageHeading">Resources</h1>
        <p className="pageBlurb">
          A short list of links to some useful general resources on the useage,
          history and research into psychedlics. We intend to add to the list in
          the future.
        </p>
      </div>
      <div className="resourcesContainer">{pageDisplay}</div>
    </div>
  );
};

export default Resources;
