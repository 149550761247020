import React from "react";
import { NavLink } from "react-router-dom";
import "./Toolbar.css";
import { slide as Menu } from "react-burger-menu";

const Toolbar = () => {

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 910;

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);


    let bar
    if(width >  breakpoint){
      bar=
      <nav className="nav">
        <div>
          <NavLink to="/" exact activeClassName="active" className="link">
            HOME
          </NavLink>
          <NavLink to="/trials" activeClassName="active" className="link">
            Trials
          </NavLink>
          <NavLink to="/classics" activeClassName="active" className="link">
            Classic Papers
          </NavLink>
          <NavLink to="/institutes" activeClassName="active" className="link">
            Research Centers
          </NavLink>
          <NavLink to="/books" activeClassName="active" className="link">
            Books
          </NavLink>
          <NavLink to="/resources" activeClassName="active" className="link">
            Resources
          </NavLink>
          </div> 
          <div>
          <NavLink to="/about" activeClassName="active" className="link">
            About/Contact
          </NavLink>
          </div>   
      </nav>
    } else {
      bar = (
        <nav className="nav">
          <Menu
            customBurgerIcon={
              // <i class="fa-solid fa-bars"></i>

  <svg viewBox="0 0 140 80" width="100" height="50" className="hamburger">
  <rect width="100" height="8"></rect>
  <rect y="30" width="100" height="8"></rect>
  <rect y="60" width="100" height="8"></rect>
  </svg>
              // <img
              //   src={
              //    "https://upload.wikimedia.org/wikipedia/commons/b/b2/Hamburger_icon.svg"
              //   }
              //   alt="burger menu icon"
              //   className="hamburger"
              // />
            }
          >
            {/* "https://upload.wikimedia.org/wikipedia/commons/5/59/Hamburger_icon_white.svg" */}
            <a id="home" className="menu-item" href="/">
              HOME
            </a>
            <a id="trials" className="menu-item" href="/trials">
              Trials
            </a>
            <a id="classics" className="menu-item" href="/classics">
              Classic Papers
            </a>
            <a id="institutes" className="menu-item" href="/institutes">
              Research Centers
            </a>
            <a id="books" className="menu-item" href="/classics">
              Books
            </a>
            <a id="resources" className="menu-item" href="/resources">
              Resources
            </a>
            <a id="resources" className="menu-item" href="/about">
              About/Contact
            </a>
          </Menu>
        </nav>
      );

    }
      

  return bar
  
};

export default Toolbar;
