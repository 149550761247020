import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import "./Display.css";
import ClassicsDisplay from "./ClassicsDisplay";
import FilterList from "./FilterList";

const Classics = () => {
  const [classic, setClassic] = useState({});
  const [filter, setFilter] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const handleDisplayAll = () => {
    setFilteredList("");
    setFilter("");
  };

  const handleFilterChange = (evt) => {
    const filterValue = evt.target.value;
    setFilter(filterValue);
    if (filterValue === "all") {
      setFilteredList("");
    } else {
      const filteredList = classic.filter((paper) =>
        paper.tags.includes(filterValue)
      );
      setFilteredList(filteredList);
    }
  };

  useEffect(() => {
    axios.get("/getClassics").then((response) => {
      const allData = response.data.classics;
      const sortedYear = allData.sort((a, b) => {
        return a.year - b.year;
      });
      setClassic(sortedYear);
    });
  }, []);

  let pageDisplay = "";

  if (filteredList.length) {
    pageDisplay = (
      <ClassicsDisplay classics={filteredList} sort="year" filter={filter} />
    );
  } else if (Object.keys(classic).length) {
    pageDisplay = (
      <ClassicsDisplay classics={classic} sort="filter" filter={null} />
    );
  } else {
    pageDisplay = (
      <Loader type="Audio" color="rgb(190, 147, 250)" height={80} width={80} />
    );
  }

  return (
    <div className="labCardContainer">
      <h1 className="pageHeading">Classic Papers</h1>
      <p className="pageBlurb">
        Here, you will find a number of PubMed indexed papers we have termed
        "classics" based purely on the number of citations they have received
        (generally from 150-1100 on "Web of Science"). Obviously, this is by no
        means an exhaustive list and many critical, early papers that are not
        indexed by PubMed are not listed. Nevertheless, these papers provide a
        good starting point for diving into the primary articles and essential
        reviews that have shaped the field.
      </p>

      {!filteredList.length ? (
        <div>
          <h2 className="classicFilterHead">All classic papers displayed</h2>
          <div className="allFilter">
            <h3 className="filterHeading">Filter by:</h3>
            <FilterList onChangeHandler={handleFilterChange} filter={filter} />
          </div>
        </div>
      ) : (
        <h2>{`Papers filtered by: ${filter} `}</h2>
      )}

      {filteredList.length ? (
        <div className="filterReset">
          {/* <h3 className="filterHeading">Filter the papers by:</h3> */}
          <FilterList onChangeHandler={handleFilterChange} filter={filter} />
          <button className="classicsBtn" onClick={handleDisplayAll}>
            Reset to show all
          </button>
        </div>
      ) : null}
      {pageDisplay}
    </div>
  );
};

export default Classics;
