import React, { useState, useEffect } from "react";
import axios from "axios";
import * as xlsx from 'xlsx'
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "./UploadAllData.css";
import { handleInstitutesUpload } from "./uploadSpreadsheets"


const UploadInstitutes = () => {
    const [workBook, setWorkBook] = useState("")

    const [institutesUploadData, setInstitutesUploadData] = useState([]);
    const [institutesUploaded, setInstitutesUploaded] = useState(false);
    const [reduxReset, setReduxReset] = useState(false);
    const [file, setFile] = useState("")
    const [loading, setLoading] = useState("")
    
    const handleFileDataUpload = (evt) => {
        setFile(evt.target.files[0])
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(evt.target.files[0])
        fileReader.onload = (e) => {
          const bufferArray = e.target.result
          const wb = xlsx.read(bufferArray, {type: "buffer"})
          setWorkBook(wb)
        }
    }

    const uploadSpreadsheets = (evt) => {
        evt.preventDefault()
        new Promise((resolve, reject) => {
          const institutesData = handleInstitutesUpload(workBook)
          if(!institutesData.length){
            setInstitutesUploaded(true)
          } else {
            setInstitutesUploadData(institutesData)
          }
          resolve()
        })
        .catch(error => console.log("Upload error " + error))
      }


      useEffect(() => {
        if (institutesUploadData.length) {
          setLoading(true)
          const institutes = institutesUploadData
          axios.post("/createInstitutesUpload", {institutes})
          .then(response => setInstitutesUploaded(true))
          .then(setLoading(false))
        }
      }, [institutesUploadData]);


      useEffect(() => {
          if (institutesUploaded) {
          alert("Data has been uploaded")
          setReduxReset(true)
        }
      }, [institutesUploaded]);

      let pageDisplay

      if (loading) {
          pageDisplay = (
            <div className="loaderContainer">
              <Loader
                type="ThreeDots"
                color="#2ca3da" 
                height={80}
                width={80}
                className="loader"
              />
              <h1 className="loaderLabel">Uploading Data...</h1>
            </div>
          );
        } else if(!reduxReset) {
          pageDisplay=
         <div className="uploadDataContainer">
          <h1 className="uploadHeader">Upload Spreadsheet Data</h1>
          <form className="uploadForm" onSubmit={uploadSpreadsheets} >
          <input type="file" onChange={handleFileDataUpload} className="fileInput"/>
          { file && 
          <input className="fileSubmitButton" type="submit" value="Upload"/>
          }
          </form>
          </div> 
          } else if (reduxReset){
              pageDisplay=
              <Redirect to={"/dataUploadPortal"}/>
          }
      return pageDisplay
      }


export default UploadInstitutes

