import './Display.css'


const FilterList = ({onChangeHandler, filter}) => {
  return (
    <select
    className="trialsDropDown"
    onChange={onChangeHandler}
    value={filter}
  >
         <option value="all" default>
          Select a category (ALL)...
        </option>
    <option value="abuse">
      Abuse
    </option>
    <option value="alcoholism">
      Alcoholism
    </option>
    <option value="anxiety">
      Anxiety
    </option>
    <option value="ayahuasca">
      Ayahuasca
    </option>
    <option value="clinical trial">Clinical trial</option>
    <option value="DMT">DMT</option>
    <option value="depression">Depression</option>
    <option value="depression">Mystical experience</option>
    <option value="ketamine">Ketamine</option>
    <option value="law">Law</option>
    <option value="LSD">LSD</option>
    <option value="MDMA">MDMA</option>
    <option value="mechanism">Mechanism</option>
    <option value="molecular">Molecular</option>
    <option value="mystical experience">Mystical experience</option>
    <option value="obsessive-compulsive disorder">Obsessive-compulsive Disorder</option>
    <option value="pain">Pain</option>
    <option value="psilocybin">Psilocybin</option>
    <option value="psychoses">Psychoses</option>
    <option value="safety">Safety</option>
    <option value="Salvinorin A">Salvinorin A</option>
    <option value="schizophrenia">Schizophrenia</option>
    <option value="tobacco addiction">Tobacco addiction</option>
    <option value="suicide">Suicide</option>
  </select>
  );
};

export default FilterList;
