import "./DateRangeFilter.css"

const DateRangeFilter = ({startDate, endDate, handleStartDate, handleEndDate}) => {
    
    return (
        <div className="dateRangeInputContainer">
            <div className="formLabelContainer">
            <label className="formLabel">Start Date</label>
            <input type="date" value={startDate} className="dateInput" onChange={handleStartDate}/>
            </div>

            <div className="formLabelContainer">
            <label className="formLabel">End Date</label>
            <input type="date" value={endDate} className="dateInput" onChange={(evt) => handleEndDate(evt.target.value)}/>
            </div>
        </div>
    )
}

export default DateRangeFilter
