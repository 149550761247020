import {
  GET_PAPERS,
  FILTER_PAPERS,
  RESET_FILTER,
  SET_ERROR,
} from "../actions/papers";

const initialState = {
  paperList: [],
  filterList: [],
  listEmpty: null,
  filterListEmpty: null,
  error: false,
  errorLength: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAPERS:
      return {
        ...state,
        paperList: action.paperList,
        listEmpty: action.empty,
        filterList: []
      };
    case FILTER_PAPERS:
      return {
        ...state,
        filterList: action.filterList,
        filterListEmpty: action.empty,
        error: action.error
      };
    case RESET_FILTER:
      return {
        ...state,
        paperList: [],
        filterList: [],
        errorLength: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        errorLength: action.errorLength
      };
    default:
      return state;
  }
};

export default reducer;
