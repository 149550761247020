import { conditions } from "../store/actions/Filters"
import './ConditionFilter.css'



const ConditionFilter = ({condition, handleConditionFilter}) => {
    const sortedConditions = conditions.sort((a,b) => a.localeCompare(b))
    return (
      <div className="formLabelContainer">
      <label className="formLabel">Condition</label>
        <select
          className="conditionDropDown"
          onChange={(evt) => handleConditionFilter(evt.target.value)}
          value={condition}
        >
            <option value="all" default>
            All conditions
          </option>
          {sortedConditions.map(cond => <option value={cond} key={cond}>{cond}</option>)}
          </select>
          </div>
      );
}

export default ConditionFilter
