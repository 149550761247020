import dayjs from "dayjs";
import axios from "axios";
import PaperList from "./PaperList";

export const GET_PAPERS = "GET_PAPERS";
export const FILTER_PAPERS = "FILTER_PAPERS";
export const RESET_FILTER = "RESET_FILTER";
export const SET_ERROR = "SET_ERROR";



export const getPapers = (days, startDate, endDate) => {
  const allunencoded  =
`(((((((((((((((psilocybin[Text Word]) OR (serotonergic hallucinogens[Text Word])) OR (psychedelic[Text Word])) OR (psychedelics[Text Word])) OR (N-methyl-D-aspartate[Text Word])) OR (lysergic acid diethylamide[Text Word])) OR (ayahuasca[Text Word])) OR (N–N-dimethyltryptamine[Text Word])) OR (5-MeO-DMT[Text Word])) OR (DMT fumarate[Text Word])) OR (salvia divinorum[Text Word])) OR (ibogaine hydrochloride[Text Word])) OR (methylenedioxymethamphetamin[Text Word])) OR (empathogen[Text Word])) OR (entactogens[Text Word])) OR (ketamine[Text Word]) AND ("`;

let urlEncoded = encodeURIComponent(allunencoded);

  
  let dateParams
  if(!startDate) {
    const dateNow = dayjs().format('YYYY/MM/DD')
    const dateMinusDays = dayjs().subtract(days, "day").format("YYYY/MM/DD");
    
    dateParams = `${dateMinusDays}"[Date - Publication] : "${dateNow}"[Date - Publication])`;
  } else {
    const startDateFormatted = dayjs(startDate).format("YYYY/MM/DD")
    const endDateFormatted = dayjs(endDate).format("YYYY/MM/DD")
    dateParams = `${startDateFormatted}"[Date - Publication] : "${endDateFormatted}"[Date - Publication])`;
  }
  ;

  const dateParamsEncoded1 = encodeURIComponent(dateParams);

  const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${urlEncoded}${dateParamsEncoded1}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;


  let idlist = [];

    return async (dispatch) => {
      await axios.get(url).then((response) => {
        idlist = [...new Set(response.data.esearchresult.idlist)];
      });
      if(idlist.length >300) {
        dispatch({ type: SET_ERROR, error: true, errorLength: idlist.length})
      } else {

    let idlistString = idlist.toString();
    let paperList = [];
    if (idlistString.length){
    await axios
      .get(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}`
      )
      .then((response) => {
        paperList = PaperList(response.data.result).sort((a,b) => parseInt(b.id)-parseInt(a.id));
      })
      .catch(err => dispatch({ type: SET_ERROR, error: true}));
    }
//  const paperListSorted = paperList.sort((a,b) => parseInt(b.id)-parseInt(a.id))

    let empty
    if (paperList.length){
      empty = false
    } else {
      empty = true
    }    
    dispatch({ type: GET_PAPERS, paperList: paperList, empty});
  };
};
}

export const filterPapers = (filterList) => {
  const allunencoded  =
  `(((((((((((((((psilocybin[Text Word]) OR (serotonergic hallucinogens[Text Word])) OR (psychedelic[Text Word])) OR (psychedelics[Text Word])) OR (N-methyl-D-aspartate[Text Word])) OR (lysergic acid diethylamide[Text Word])) OR (ayahuasca[Text Word])) OR (N–N-dimethyltryptamine[Text Word])) OR (5-MeO-DMT[Text Word])) OR (DMT fumarate[Text Word])) OR (salvia divinorum[Text Word])) OR (ibogaine hydrochloride[Text Word])) OR (methylenedioxymethamphetamin[Text Word])) OR (empathogen[Text Word])) OR (entactogens[Text Word])) OR (ketamine[Text Word]) AND ("`;
  
  let urlEncoded = encodeURIComponent(allunencoded);
  




  const {days, drugFilter, conditionFilter, startDate, endDate} = filterList

  let dateParams
  if(!startDate) {
    const dateNow = dayjs().format('YYYY/MM/DD')
    const dateMinusDays = dayjs().subtract(days, "day").format("YYYY/MM/DD");
    
    dateParams = `${dateMinusDays}[Date - Publication] : ${dateNow}[Date - Publication])`;
  } else {
    const startDateFormatted = dayjs(startDate).format("YYYY/MM/DD")
    const endDateFormatted = dayjs(endDate).format("YYYY/MM/DD")
    dateParams = `${startDateFormatted}"[Date - Publication] : "${endDateFormatted}"[Date - Publication])`;
  }
  
let urlunencoded
 if (drugFilter !=="all" && conditionFilter === "all") {
  urlunencoded = `((${drugFilter}[Text Word]) AND (`
 } else if (drugFilter !=="all" && conditionFilter !== "all") {
  urlunencoded = `(${drugFilter}[Text Word]) AND (${conditionFilter}[Text Word]) AND ("`
 } else if (drugFilter === "all" && conditionFilter !== "all") {
  urlunencoded= `${allunencoded}${conditionFilter}"[Text Word]) AND ("`
 }else if (drugFilter === "all" && conditionFilter === "all") {
  urlunencoded= allunencoded
 }


  urlEncoded = encodeURIComponent(urlunencoded);
  const dateParamsEncoded = encodeURIComponent(dateParams);

  const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${urlEncoded}${dateParamsEncoded}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;
 


  let idlist = [];

  return async (dispatch) => {
    await axios.get(url).then((response) => {
      console.log(response.data.esearchresult.idlist)
      idlist = [...new Set(response.data.esearchresult.idlist)];
    });

    if(idlist.length >300) {
      dispatch ({ type: SET_ERROR, error: true, errorLength: idlist.length})
    } else {

    let idlistString = idlist.toString();
  
    let filterList = [];
    if (idlistString.length){
    await axios
      .get(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}`
      )
      .then((response) => {
        filterList = PaperList(response.data.result);
      })
      .catch((error) => console.log("Error" + error));
    }
    const filterListSorted = filterList.sort((a,b) => parseInt(b.id)-parseInt(a.id))
  
    let empty
    if (filterListSorted.length){
      empty = false
    } else {
      empty = true
    }
    dispatch({ type: FILTER_PAPERS, filterList:filterListSorted, empty});
  };
}
};


export const resetFilter = () => {
  return { type: RESET_FILTER};
};


export const resetError = () => {
  return { type: SET_ERROR, error: false};
};


