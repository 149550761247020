import Papers from "./papers/Papers";
// import Banner from "./Banner";
import "./Display.css";

const Home = () => {
  return (
    <div className="homeContainer">

            <div className="bannerContainer">
            <h1 className="bannerHeading">Psychedelic Research Portal</h1>
            </div>
      
    
        <p className="infoBlurb">
          Research into psychedelic drugs and their therapeutic application(s) has reached a whole new level of legitimacy in recent years. However, the field remains clouded by misinformation without scientific basis. Here, we aim to provide real-time, up-to-date, access for scientists and non-scientists alike to all current research being performed on psychedelics. See below for the latest research or click on links in the menubar for clinical trials, classic papers, books and other resources.
        </p>
        
        <div className="papersHomeContainer">
        <Papers />
        </div>
        
      </div>
   
 
   
  );
};

export default Home;
