import "./Display.css";

const About = () => {
  return (
    <div className="about">
      <h1 className="pageHeading">About/Contact</h1>
      <p>
        This site was constructed by biochemist/cell biologist/cancer researcher
        and web app developer, Doug Fairlie (
        <a href="https://www.celldeathlab.com" target="_blank" rel="noreferrer">
          Lab
        </a>
        ) from Melbourne, Australia.{" "}
      </p>
      <p>
        Although pschedelics are not his primary research focus, he is deeply
        impressed with the current state of psychedlics research and the
        application of psychedlic drugs to the treatment of a range of
        conditions, and thought it would be a great subject to apply to the
        educational web platform he is developing (see also{" "}
        <a href="https://bcl2.co" target="_blank" rel="noreferrer">
          Website
        </a>{" "}
        for another example). Nevertheless, Doug is not an expert in the field
        and there may be some mistakes, omissions, oversights he has made in
        constructing this site. Accordingly, if you have any suggestions for how
        the site could be improved, or corrections that should be made, please
        feel free to contact him at: psychedelicresearchportal@gmail.com.
      </p>
    </div>
  );
};

export default About;
