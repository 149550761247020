import { drugs } from "../store/actions/Filters"
import './DrugFilter.css'


const DrugFilter = ({handleDrugFilter, drug}) => {
  
  const sortedDrugs = drugs.sort((a,b) => a.localeCompare(b))
  
  return (
    <div className="formLabelContainer">
    <label className="formLabel">Drug / Category</label>
    <select
      className="drugDropDown"
      onChange={(evt) => handleDrugFilter(evt.target.value)}
      value={drug}
    >
        <option value="all" default className="drugDropDown">
        All drugs
      </option>
      {sortedDrugs.map(drug => <option value={drug} key={drug}>{drug}</option>)}
      </select>
      </div>
  );
};

export default DrugFilter;
