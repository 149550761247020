import "./Display.css";
const ResourcesDisplay = ({ resources }) => {

  const page = resources.map((resource, idx) => {
    return (
      <div className="datePlusHeading" key={idx}>
    
        {idx === 0 ? (
        <p className="yearHeading">{resource.category}</p>
      ) : resources[idx - 1].category !== resource.category ? (
        <p className="yearHeading">{resource.category}</p>
      ) : null}      
      
      
      <div className="resource" key={resource.website}>
        <p className="resourceHead">{`${resource.resource} `}</p>
        <p className="resourceDescription">{resource.description}</p>

        <a
          rel="noreferrer"
          target="_blank"
          href={resource.website}
          className="resourceLink"
        >
          Website
        </a>
      </div>
      </div>
    );
  });
  return page;
};

export default ResourcesDisplay;