import dayjs from "dayjs";
import "./PaperDisplay.css";

const PaperDisplay = ({ paperlist, filtered, days, startDate, endDate }) => {
  let dateParams;

  if (startDate) {
    dateParams = `between ${dayjs(startDate).format("DD/MM/YYYY")} and ${dayjs(
      endDate
    ).format("DD/MM/YYYY")}`;
  } else {
    dateParams = `in the last ${days} days`;
  }

  let listHeader;

  if (!filtered) {
    listHeader = (
      <h2 className="displayModeHeading">{`All psycheldelic research papers ${dateParams} (${paperlist.length} in total).`}</h2>
    );
  } else {
    listHeader = (
      <h2 className="displayModeHeading">
        {`Papers identified using the filter criteria ${dateParams} (${paperlist.length} in total)`}
      </h2>
    );
  }

  let listDisplay = "";

  if (paperlist.length) {
    listDisplay = paperlist.map((data) => {
      let volume = "";
      if (data.volume === "") {
        volume = " volume/pages not yet available";
      } else {
        volume = `${data.volume}: `;
      }

      return (
        <div className="paperlistContainer" key={data.id}>
          <span>
            <span className="title">{`${data.title} `}</span>
            <span className="authors">{`${data.authors}, `}</span>
            <span className="year">{`(${data.pubdate}), `}</span>
            <span className="journal">{`${data.journal},  `}</span>
            <span className="volume">{`${volume} `}</span>
            <span className="pages">{`${data.pages},  `}</span>
            <span className="doi">{`${data.doi}, `}</span>
            <span className="pmid">
              PMID:{" "}
              <a
                href={`https://www.ncbi.nlm.nih.gov/pubmed/${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.id}.
              </a>
            </span>
          </span>
        </div>
      );
    });
  } else {
    listDisplay = (
      <h3 className="noPapersMessage">No papers matching that search.</h3>
    );
  }

  return (
    <>
      {listHeader}
      {listDisplay}
    </>
  );
};

export default PaperDisplay;
