import "./Display.css"

const BookFilterList = ({onChangeHandler, filter}) => {
    return (
      <select
        className="dropDown"
        onChange={onChangeHandler}
        value={filter}
      >
        <option value="all" default>
          Select a category (ALL)...
        </option>
        <option value="Classic">Classic</option>
        <option value="DMT">DMT</option>
        <option value="Experiences">Experiences</option>
        <option value="General">General</option>
        <option value="History">History</option>
        <option value="LSD">LSD</option>
        <option value="Mescaline">Mescaline</option>
        <option value="Psilocybin">Psilocybin</option>
        <option value="Reference">Reference</option>
        <option value="Therapy">Therapy</option>
        

      </select>
    );
  };
  
  export default BookFilterList;
  