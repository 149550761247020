import "./UploadAllData.css";

const DataUploadPortal = () => {

    return (
        <div style={{marginTop: "100px"}}>
            <a href="/uploadClassics"><button>Upload Classic Papers</button></a>
       
            <a href="/uploadBooks"><button>Upload Books</button></a>
           <a href="/uploadInstitutes"><button>Upload Institutes</button></a>

           <a href="/uploadResources"><button>Upload Resources</button></a>
       </div>

    )
}

export default DataUploadPortal