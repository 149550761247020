import './DateDropdown.css'

const DateDropdown = ({handleDays, days}) => {
    return (
        <div className="formLabelContainer">
        {/* <label className="formLabel">Days</label> */}
        <select
        className="daysDropDown"
        onChange={handleDays}
        value={days}>
            {/* <option value={1}>Last 1 days</option> */}
            <option value={7}>Last 7 days</option>
            <option value={14}>Last 2 weeks</option>
            <option value={30}>Last 1 month</option>
            <option value={60}>Last 2 months</option>
            <option value={90}>Last 3 months</option>
            <option value={120}>Last 4 months</option>
            <option value={180}>Last 6 months</option>
            <option value={365}>Last 12 months</option>
        </select>
        </div>
    )
}

export default DateDropdown