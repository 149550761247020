import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import "./Display.css";
import InstitutesDisplay from "./InstitutesDisplay";

const Institutes = () => {
  const [institutes, setInstitutes] = useState({});

  useEffect(() => {
    axios.get("/getInstitutes").then((response) => {
      const allData = response.data.institutes;
      const sortedData = allData.sort((a, b) => {
        return a.country < b.country ? -1 : a.country > b.country ? 1 : 0;
      });
      setInstitutes(sortedData);
    });
  }, []);

  let pageDisplay = "";

  if (Object.keys(institutes).length) {
    pageDisplay = <InstitutesDisplay institutes={institutes} sort="filter" />;
  } else {
    pageDisplay = (
      <Loader type="Audio" color="rgb(190, 147, 250)" height={80} width={80} />
    );
  }

  return (
    <div className="labCardContainer">
      <h1 className="pageHeading">
        {" "}
        Research Centers, Institutes and Foundations
      </h1>
      <p className="pageBlurb" style={{marginBottom: "20px"}}>
        Below is a list of research centres worldwide, either stand-alone or
        affiliated with universities, that primarily focus on psychedelics
        research. The "About" blurbs have been copied from each centre's
        website.
      </p>
      {pageDisplay}
    </div>
  );
};

export default Institutes;
