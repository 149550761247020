import "./Display.css";

const Trials = ({ trials }) => {
  const pageDisplay = trials.map((trial, idx) => {
    return (
      <div className="labCard" key={idx}>
        <p className="labCardHeading">{`${trial.BriefTitle} (${trial.NCTId})`}</p>
        <p className="trialDetail">{`Condition(s): ${trial.Condition}`}</p>
        <p className="trialDetail">{`Sponsor: ${trial.LeadSponsorName}`}</p>
        <p className="trialDetail">{`Location: ${trial.LocationCountry}`}</p>
        <p className="trialDetail">{`Start Date: ${trial.StartDate}`}</p>
        <p className="trialDetail">{`Status: ${trial.OverallStatus}`}</p>
        <p className="labCardSubHeading">Brief summary</p>
        <p className="cardBlurb">{trial.BriefSummary}</p>
      </div>
    );
  });

  return <div className="trials">{pageDisplay}</div>;
};

export default Trials;
