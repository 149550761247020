import "./Display.css";

const BooksDisplay = ({ books, filter }) => {
  const page = books.map((book, idx) => {
    return (
      <div className="datePlusHeading" key={book.title}>
        {filter ? (
          <p></p>
        ) : idx === 0 ? (
          <p className="yearHeading">{book.primaryCategory}</p>
        ) : books[idx - 1].primaryCategory !== book.primaryCategory ? (
          <p className="yearHeading">{book.primaryCategory}</p>
        ) : null}
        <div className="labCard" style={{ width: "100%" }}>
          <div className="titleImage">
            <img
              src={book.imageUrl}
              alt="book over"
              style={{ width: "50px", height: "auto", marginRight: "10px" }}
            />
            <p className="labCardHeading">{book.title}</p>
          </div>
          <div className="labCardElements">
            <p className="labCardLabel">Author:{` ${book.author}`}</p>
            <a
              href={book.affiliateLink}
              rel="noreferrer"
              target="_blank"
              className="labCardLabel"
            >
              Amazon Link
            </a>

            <p style={{ marginBottom: 0, fontWeight: "bold" }}>
              Publisher Blurb
            </p>
            <p style={{ marginTop: "2px", textAlign: "justify" }}>
              {book.blurb}
            </p>
          </div>
        </div>
      </div>
    );
  });
  return page;
};

export default BooksDisplay;
