import * as xlsx from 'xlsx'

export const handleInstitutesUpload = (workBook) => {
    const ws = workBook.Sheets["Institutes"]
    const data = xlsx.utils.sheet_to_json(ws)
    let renameInstitutesData = data.map(item => {
      return {
      institute: item.Institute,
      location: item.Location,
      country: item.Country,
      region: item.Region,
      website: item.Website,
      about: item.About
      }
    })
  return renameInstitutesData
  }

export const handleResourcesUpload = (workBook) => {
    const ws = workBook.Sheets["Resources"]
    const data = xlsx.utils.sheet_to_json(ws)
    const renameResourcesData = data.map(item => {
      return {
      resource: item.Resource,
      website: item.Website,
      description: item.Description,
      category: item.Category,
      }
    })
    return renameResourcesData
  }


export const handleBooksUpload = (workBook) => {
  const ws = workBook.Sheets["Books"]
  const data = xlsx.utils.sheet_to_json(ws)
  const renameBooksData = data.map(item => {
    return {
      title: item.Title,
      author: item.Author,
      blurb: item.Blurb,
      link: item.Amazon,
      category: item.Category,
      primaryCategory: item.Primary_Category,
      imageUrl: item.Image_URL,
      affiliateLink: item.AffiliateLink
    }
  })
  return renameBooksData
}

export const handleClassicsUpload = (workBook) => {
    const ws = workBook.Sheets["Classics"]
    const data = xlsx.utils.sheet_to_json(ws)
    let renameClassicsData = data.map(item => {
      return {
      title: item.Title,
      year: item.Year,
      authors: item.Authors,
      reference: item.Reference,
      citations: item.Citations,
      abstract: item.Abstract,
      tags: item.Tags,
      primaryTag: item.Primary_Tag,
      pmid: item.PMID,
      link: item.Paper_Link,
      doi: item.DOI
      }
    })
  return renameClassicsData
  }