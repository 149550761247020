import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import "./Display.css";
import BooksDisplay from "./BooksDisplay";
import BookFilterList from "./BookFilterList";

const Books = () => {
  const [books, setBooks] = useState({});
  const [filter, setFilter] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const handleDisplayAll = () => {
    setFilteredList("");
    setFilter("");
  };

  const handleFilterChange = (evt) => {
    const filterValue = evt.target.value;
    setFilter(filterValue);
    if (filterValue === "all") {
      setFilteredList("");
    } else {
      const filteredList = books.filter((book) =>
        book.category.includes(filterValue)
      );
      setFilteredList(filteredList);
    }
  };

  useEffect(() => {
    axios.get("/getBooks").then((response) => {
      const allData = response.data.books;
      const sortedData = allData.sort((a, b) => {
        return a.primaryCategory < b.primaryCategory
          ? -1
          : a.primaryCategory > b.primaryCategory
          ? 1
          : 0;
      });
      setBooks(sortedData);
    });
  }, []);

  let pageDisplay = "";

  if (filteredList.length) {
    pageDisplay = <BooksDisplay books={filteredList} filter={filter} />;
  } else if (Object.keys(books).length) {
    pageDisplay = <BooksDisplay books={books} sort="filter" filter={null} />;
  } else {
    pageDisplay = (
      <Loader type="Audio" color="rgb(190, 147, 250)"  height={80} width={80} />
    );
  }

  return (
    <div className="labCardContainer">
      <h1 className="pageHeading">Books</h1>
      <p className="pageBlurb">
        Here is a selection of books that will help you learn a lot more about
        psychedelics ranging across their history, personal experiences with
        various compounds, reference books and some classics. Note, if you
        purchase any of them using the Amazon link, we receive a small
        commission that helps to maintain this site.
      </p>

      {!filteredList.length ? (
        <div>
          <h2 className="classicFilterHead">All books displayed</h2>
          <div className="allFilter">
            <h3 className="filterHeading">Filter by:</h3>
            <BookFilterList
              onChangeHandler={handleFilterChange}
              filter={filter}
            />
          </div>
        </div>
      ) : (
        <h2>{`Books filtered by: ${filter} `}</h2>
      )}

      {filteredList.length ? (
        <div className="filterReset">
          <BookFilterList
            onChangeHandler={handleFilterChange}
            filter={filter}
          />
          <button className="classicsBtn" onClick={handleDisplayAll}>
            Reset to show all
          </button>
        </div>
      ) : null}
      {pageDisplay}
    </div>
  );
};

export default Books;
