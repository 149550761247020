import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Toolbar from "./Toolbar";
import Home from "./Home"
import ClinicalTrials from "./ClinicalTrials"
// import UploadAllData from "./UploadAllData";
// import UpdateData from "./UpdateData";
import Books from "./Books";
import Papers from "./papers/Papers";
import Classics from "./Classics"
import Institutes from "./Institutes"
import Resources from "./Resources"
import About from "./About"
import DataUploadPortal from "./DataUploadPortal"
import UploadClassics from "./utility components/UploadClassics"
import UploadInstitutes from "./utility components/UploadInstitutes"
import UploadResources from "./utility components/UploadResources"
import UploadBooks from "./utility components/UploadBooks"
import './App.css';

const App = ({ history }) => {
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <Toolbar />
        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/papers" render={() => <Papers />} />
          <Route exact path="/trials" render={() => <ClinicalTrials />} />
          <Route exact path="/dataUploadPortal" render={() => <DataUploadPortal />} />
          {/* <Route exact path="/uploadAllData" render={(props) => <UploadAllData {...props}/>} />
          <Route exact path="/updateData" render={(props) => <UpdateData {...props}/>} /> */}
          <Route exact path="/uploadClassics" render={(props) => <UploadClassics {...props}/>} />
          <Route exact path="/uploadInstitutes" render={(props) => <UploadInstitutes {...props}/>} />
          <Route exact path="/uploadResources" render={(props) => <UploadResources {...props}/>} />
          <Route exact path="/uploadBooks" render={(props) => <UploadBooks {...props}/>} />
          <Route exact path="/classics" render={(props) => <Classics {...props}/>} />
          <Route exact path="/institutes" render={(props) => <Institutes {...props}/>} />
          <Route exact path="/resources" render={(props) => <Resources {...props}/>} />
          <Route exact path="/books" render={(props) => <Books {...props}/>} />
          <Route exact path="/about" render={(props) => <About {...props}/>} />
          </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
