import "./Display.css";

const TrialsFilterList = ({ filter, onChangeHandler }) => {
  return (
    <select
      className="trialsDropDown"
      onChange={onChangeHandler}
      value={filter}
      //   name={filterName}
    >
      <option value="ayahuasca" name="Ayahuasca">
        Ayahuasca
      </option>
      <option value="dimethyltryptamine">DMT</option>
      <option value="entheogen">Entheogens</option>
      <option value="ibogaine">Ibogaine</option>
      <option value="kambo">Kambo</option>
      <option value="ketamine">Ketamine</option>
      <option value="lysergic acid diethylamide">LSD</option>
      <option value="MDMA">MDMA</option>
      <option value="mescaline">Mescaline</option>
      <option value="psilocybin">Psilocybin</option>
      <option value="sanaga">Sanaga</option>
    </select>
  );
};

export default TrialsFilterList;
