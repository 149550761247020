const InstitutesDisplay = ({institutes}) => {

  
    const page = institutes.map((institute, idx) => {

      return (
        <div className="datePlusHeading" key={idx}>
            {idx === 0 ? (
            <p className="yearHeading">{institute.country}</p>
          ) : institutes[idx - 1].country !== institute.country ? (
            <p className="yearHeading">{institute.country}</p>
          ) : null}
          <div className="labCard" style={{ width: "100%" }}>
            <p className="labCardHeading">{institute.institute}</p>
            <div className="labCardElements">
              <p className="labCardLabel">{`${institute.location}`}</p>
              <span className="pubmedLink">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={institute.website}
                  className="labCardLabel"
                  style={{ margin: 0 }}
                >Website</a>
              </span>
              <p className="labCardSubHeading">About</p>
            <p className="cardBlurb" style={{ marginTop: "2px", textAlign: "justify" }}>
                   {institute.about}
                   </p>
            </div>
          </div>
          </div>
      );
    });
    return page;
  };
  
  export default InstitutesDisplay;
  