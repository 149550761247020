import React, { useState, useEffect } from "react";
import axios from "axios";
import Trials from "./Trials";
import TrialsFilterList from "./TrialsFilterList";
import Loader from "react-loader-spinner";
import "./Display.css";

const ClinicalTrials = () => {
  const [trials, setTrials] = useState([]);
  const [filterValue, setFilterValue] = useState("lysergic acid diethylamide");

  const [loading, setLoading] = useState(true);

  const handleFilterChange = (evt) => {
    setFilterValue(evt.target.value);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://clinicaltrials.gov/api/query/study_fields?expr=${filterValue}&fields=NCTId%2CBriefTitle%2CBriefSummary%2CCondition%2CLeadSponsorName%2CLocationCountry%2CLocationFacility%2CStartDate%2COverallStatus&min_rnk=1&max_rnk=1000&fmt=JSON`
      )
      .then((response) => {
        setTrials(response.data.StudyFieldsResponse.StudyFields);
      })
      .then((reponse) => setLoading(false));
  }, [filterValue]);

  let pageDisplay;
  if (loading) {
    pageDisplay = (
      <>
        <Loader
          type="Audio"
          color="rgb(190, 147, 250)"
          height={80}
          width={80}
          className="loader"
        />
        <h3 className="loaderMessage">Searching...</h3>
      </>
    );
  } else {
    pageDisplay = (
      <div className="labCardContainer">
        <h1 className="pageHeading">Clinical Trials</h1>
        <p className="pageBlurb">
          Below is a list of clinical trial either recruiting, on-going or
          completed, identified using the US National Institute of Health
          (ClinicalTrials.gov) API. Data is updated on this page in real-time.
        </p>
        <div className="trialsSearchNameContainer">
          <h3 className="trialsSearchName">Displaying clinical trials on:</h3>
          <h3 className="trialsSearchName2">{filterValue}</h3>
        </div>
        <div className="dropDownContainer">
          <h4 className="trialsSearchFilterLabel">Filter by: </h4>
          <TrialsFilterList
            filter={filterValue}
            onChangeHandler={handleFilterChange}
          />
        </div>
        {trials?.length ? (
          <Trials trials={trials} loading={loading} />
        ) : (
          <p>No trials with that search term found</p>
        )}
      </div>
    );
  }

  return pageDisplay;
};

export default ClinicalTrials;
