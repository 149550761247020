import React, { useEffect, useState } from "react";
import dayjs from 'dayjs'
import PaperDisplay from "./PaperDisplay";
import Loader from "react-loader-spinner";
import DrugFilter from "./DrugFilter";
import ConditionFilter from "./ConditionFilter";
import DateDropdown from "./DateDropdown";
import DateRangeFilter from "./DateRangeFilter"
import { useSelector, useDispatch } from "react-redux";
import * as allActions from "../store/actions/papers";
import "./PaperDisplay.css";

const Papers = () => {
  const error = useSelector((state) => state.papers.error);
  const filterList = useSelector((state) => state.papers.filterList);
  const paperList = useSelector((state) => state.papers.paperList);
  // const loaded = useSelector((state) => state.papers.loaded);
  const filterListEmpty = useSelector((state) => state.papers.filterListEmpty);
  const listEmpty = useSelector((state) => state.papers.listEmpty);
  const errorLength = useSelector((state) => state.papers.errorLength);

 
  const dateNow = dayjs().format('YYYY/MM/DD')
  const [loading, setLoading] = useState(true);
  const[days, setDays] = useState(7)
  const[startDate, setStartDate] = useState("")
  const[endDate, setEndDate] = useState(dateNow)
  const[drug, setDrug] = useState("all")
  const[condition, setCondition] = useState("all")
  const[getFilter, setGetFilter] = useState(false)
  const[resetFilter, setResetFilter] = useState(false)
  const[showDateRange, setShowDateRange] = useState(false)
  const[recency, setRecency] = useState(7)
  const[newStartDate, setNewStartDate] = useState("")
  const[newEndDate, setNewEndDate] = useState(dateNow)

  const dispatch = useDispatch();

  const handleDays = (evt) => {
    evt.preventDefault()
    setRecency(evt.target.value)
  };

  
  const handleStartDate = (evt) => {
    evt.preventDefault()
    setNewStartDate(evt.target.value)
  }

  const handleEndDate = (date) => {
    setNewEndDate(date)

  }
  
  const handleDrugFilter = (drug) => {
    setDrug(drug)
  }

  const handleConditionFilter = (condition) => {
    setCondition(condition)
  }

  const handleFilterConditions =  async () => {
    if (recency !== days) {
      await setDays(recency)
    }
    if (newStartDate !==startDate) {
      await setStartDate(newStartDate)
    }
    if (newEndDate !==endDate) {
      await setEndDate(newEndDate)
    }
    await setGetFilter(true)
  }


  useEffect(() => { 
    setLoading(true);
    if(paperList.length || filterList.length){
          dispatch(allActions.resetFilter())
    }
          dispatch(allActions.getPapers(days, startDate, endDate)).then(() => setLoading(false))
    }, [])


    useEffect(() => { 
      setLoading(true);
      if(resetFilter) {
          dispatch(allActions.resetFilter())
          setCondition("all")
          setDrug("all")
          setDays(7)
          setStartDate("")
          setEndDate(dateNow)
          setLoading(false)
          setResetFilter(false)
          setRecency(7)
          setGetFilter(true)
          setNewStartDate("")
          setNewEndDate(dateNow)
      }  else {
        setLoading(false)
      }}, [resetFilter])
  

 
    useEffect(() => {
    if (error) {
      alert(
        `Sorry - there was an error on the Pubmed server. This could be due to too many papers being requested which is usually corrected by using a shorter timeframe or more specific criteria (drug/condition) for your search. (Your search generated ${errorLength} papers - maximum is 300.)
        
         If the number of results is less that 300 it is probably an issue with the server itself. Try refreshing your browser. If that doesnt work try again and/or reprot it to us to investigate (see "About/Contact" page for details).`
      );
      dispatch(allActions.resetError())
        setLoading(false)
        setResetFilter(true)
        setGetFilter(false)
        setRecency(7)
        setDays(7)
        setStartDate("")
        setEndDate(dateNow)
        setNewStartDate("")
        setNewEndDate(dateNow)
        setDrug("all")
        setCondition("all")
    } else if (getFilter && condition === "all" && drug==="all") {
      setLoading(true);
      dispatch(allActions.getPapers(days, startDate, endDate)).then(() => {
        setLoading(false)
        setGetFilter(false)
      }
      );
    } else if (getFilter) {
        setLoading(true);
        dispatch(allActions.filterPapers({days, drugFilter:drug, conditionFilter:condition, startDate:startDate, endDate:endDate})).then(() => {
          setLoading(false)
          setGetFilter(false)
        }
        );
      } 
  }, [error, getFilter]);



  let listDisplay;
  
  if (loading) {
    listDisplay = (
      <>
      <Loader
        type="Audio"
        color="rgb(190, 147, 250)"
        height={80}
        width={80}
        className="loader"
      />
      <h2 className="fetchingText">Fetching Papers...</h2>
      </>
    );
  } else if(filterListEmpty || listEmpty){
      listDisplay = <p className="noPapersMessage">No papers returned using that/those search parameters. Perhaps expand timeframe or use other search terms.</p>
    } else if (filterList.length){
      listDisplay = <PaperDisplay paperlist={filterList} filtered={true} days={days}  startDate={startDate} endDate={endDate}/>;
    } else if (paperList.length){
      listDisplay = <PaperDisplay paperlist={paperList} filtered={false} days={days} startDate={startDate} endDate={endDate}  />;
    }
  
    if(paperList.length || filterList.length || filterListEmpty || listEmpty) {
  return (
    <>
      <div className="filterContainer">
        <h3 className="filterSectionHeader">Refine search parameters</h3>
 
        <div className="drugFilterContainer">
        <DrugFilter  handleDrugFilter={handleDrugFilter} drug={drug} className="filterItem" />
        
        <ConditionFilter handleConditionFilter={handleConditionFilter} condition={condition} className="filterItem" />
        </div>
        

        <div className="dateFilterContainer">
        
        <div className="recencyDropdown">
        <h3 className="filterSubheading">Recency: </h3>
        <DateDropdown
          days={recency}
          handleDays={handleDays}
          className="filterItem"
        />
        </div>

        
        <div className="dateRangeButtonContainer">
       
        
        
        {showDateRange ? 
        <>
      
        <DateRangeFilter handleStartDate={handleStartDate} handleEndDate={handleEndDate} startDate={newStartDate} endDate={newEndDate}className="filterItem"/>
      
        <p className="dateWarning">You must enter a Start date. End date defaults today.</p>
    

        </>
        : null
        }
 <button onClick={() => setShowDateRange(!showDateRange)} className="showDateRangeButton">{!showDateRange ? "Select specific dates" : "Hide date selector"}</button>

        <div className="filterButtonContainer">
        <button onClick={handleFilterConditions} className="filterButton">Apply parameters</button>
        {(filterList.length || days !== 7 || startDate) && <button onClick={() => setResetFilter(true)} className="filterButton">Reset default</button>
        }
        </div>
      </div>
      </div>
      </div>
      
    {listDisplay}
    
     </>
  );
      } else { 
        return (
        <>
        <Loader
          type="Audio"
          color="rgb(190, 147, 250)"
          height={100}
          width={100}
          className="loader"
        />
        <h2 className="fetchingText">Fetching Papers...</h2>
        </>
      )
}
};

export default Papers;
