import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose} from 'redux';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import paperReducer from './store/reducers/papers'
import authReducer from './store/reducers/auth'


const history = createBrowserHistory()
const rootReducer= (history) => combineReducers({router: connectRouter(history), papers: paperReducer, auth: authReducer})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store =createStore(rootReducer(history), composeEnhancer(applyMiddleware(ReduxThunk, routerMiddleware(history))))


ReactDOM.render(
  <Provider store={store}>
    <App history={history}/>
  </Provider>,
  document.getElementById('root')
);

